<template>
  <div class="errPage-container">
    <div class="401_container" style="width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);">
      <!-- <router-link replace to> -->
      <el-button icon="el-icon-arrow-left" class="pan-back-btn" @click="$GOBACK">
        返回
      </el-button>
      <!-- </router-link> -->

      <el-row>
        <el-col :span="12">
          <h1 class="text-jumbo text-ginormous">
            Oops!
          </h1>
          gif来源<a href="https://zh.airbnb.com/" target="_blank">airbnb</a> 页面
          <h2>你没有权限去该页面</h2>
          <h6>如有不满请联系你领导</h6>
          <ul class="list-unstyled">
            <li>或者你可以去:</li>
            <li class="link-type">
              <router-link replace to="/home">
                回首页
              </router-link>
            </li>
            <li class="link-type">
              <a href="https://www.baidu.com/">随便看看</a>
            </li>
            <li><a href="#" @click.prevent="dialogVisible=true">点我看图</a></li>
          </ul>
        </el-col>
        <el-col :span="12">
          <img :src="errGif" width="313" height="428" alt="Girl has dropped her ice cream.">
        </el-col>
      </el-row>
      <el-dialog :visible.sync="dialogVisible" title="随便看">
        <img :src="ewizardClap" class="pan-img">
      </el-dialog>
    </div>
  </div>
</template>

<script>
import errGif from '@/assets/401_images/401.gif'

export default {
  name: 'Page401',
  data() {
    return {
      errGif: errGif + '?' + +new Date(),
      ewizardClap: 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646',
      dialogVisible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.errPage-container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  // width: 800px;
  // max-width: 100%;
  // margin: 100px auto;
  position: relative;
  .pan-back-btn {
    background: #008489;
    color: #fff;
    border: none !important;
  }
  .pan-gif {
    margin: 0 auto;
    display: block;
  }
  .pan-img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  .text-jumbo {
    font-size: 60px;
    font-weight: 700;
    color: #484848;
  }
  .list-unstyled {
    font-size: 14px;
    li {
      padding-bottom: 5px;
    }
    a {
      color: #008489;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
